import schttp from 'public/src/services/schttp'

export const getTrackNewData = async billno => {
  const result = await schttp({
    url: '/api/orders/track/queryTrackData/query',
    params: { billno }
  })
  return result
}

export const getDisabledConfig = ({ orderInfo, TRANSPORT_TYPE_SHOP, updateShippedAddress, disabledAddress = [] }) => {
  let { shipping_country_id = '', transport_type = '', subsidiary = {} } = orderInfo || {}
  transport_type = transport_type || subsidiary?.transport_type || ''

  if (!TRANSPORT_TYPE_SHOP.includes(transport_type)) {
    let shippedAddressArr = updateShippedAddress['other']?.[shipping_country_id]
    if (shippedAddressArr) {
      let isObject = Object.prototype.toString.call(shippedAddressArr) === '[object Object]'
      disabledAddress = isObject ? shippedAddressArr[transport_type] || [] : shippedAddressArr
    }
  } else {
    disabledAddress = updateShippedAddress['shop_transit'] || []
  }
  return disabledAddress
}

export const getExtraConfig = ({ shipInfo }) => {
  let { countryId } = shipInfo || {}
  return countryId == '209' ? ['isDefault', 'certificateTw'] : ['isDefault', 'type', 'certificateTw']
}

export const initShipAddressConfig = ({ order }) => {
  return {
    fname: order.shipping_firstname,
    lname: order.shipping_lastname,
    fatherName: order.shipping_father_name,
    englishName: order.shipping_english_name || order.subsidiary?.english_name || '',
    middleName: order.shipping_middle_name || order.subsidiary?.middle_name || '',
    countryId: `${order.shipping_country_id}`,
    country_name: order.shipping_country_name,
    state: order.shipping_province,
    city: order.shipping_city,
    district: order.shipping_district,
    street: order.shipping_street,
    address1: order.shipping_address1 || order.shipping_address_1 || '',
    address2: order.shipping_address2 || order.shipping_address_2 || '',
    postcode: order.shipping_postcode,
    taxNumber: order.shipping_tax_number || order.tax_number || '',
    nationalId: order.shipping_national_id || order.nationalId || '',
    tel: order.shipping_telephone || order.shipping_telephone_cipher || '',
    new_tel: order.shipping_telephone || order.shipping_telephone_cipher || '',
    standbyTel: order.backupShippingTelephone || order.shipping_telephone_standby_cipher || '',
    birthday: order.birthday,
    isDefault: 0,
    type: order.shipping_address_type || order.type || '',
    memberId: order.member_id,
    stateId: `${order.shipping_state_id}`,
    cityId: `${order.shipping_city_id}`,
    districtId: `${order.shipping_district_id}`,
    storeType: order.store_type || order.subsidiary?.store_type,
    storeId: order.store_id || order.subsidiary?.store_id,
    storeName: order.store_name || order.subsidiary?.store_name,
    passportNumber: order.shipping_passport_number || order.subsidiary?.passport_number,
    passportIssuePlace: order.shipping_issue_place || order.subsidiary?.issue_place,
    passportIssueDate: order.shipping_issue_date || order.subsidiary?.issue_date,
    lat: order.shipping_lat,
    lng: order.shipping_lng,
    calendar_type: order.calendar_type,
    id_expire_date: order.id_expire_date
  }
}

export const updateStoreAddress = ({ formData = {}, shipInfo = {} }) => {
  let updateOrderInfo = {
    fname: formData?.fname,
    lname: formData?.lname,
    tel: formData?.tel,
    standbyTel: formData?.standbyTel,
    city: formData?.city,
    storeName: formData?.storeName,
    storeId: formData?.storeId
  }
  if (shipInfo?.countryId == 209) {
    updateOrderInfo = {
      ...updateOrderInfo,
      district: formData?.district,
      street: formData?.street,
      storeType: formData?.storeType,
      standbyTel: formData.standby_tel || ''
    }
  } else {
    updateOrderInfo = {
      ...updateOrderInfo,
      address1: formData?.address1,
      address2: '',
      postcode: formData?.postcode
    }
    if (shipInfo.countryId == 178) {
      updateOrderInfo = {
        ...updateOrderInfo,
        englishName: formData?.english_name,
        fatherName: formData?.father_name,
        state: formData?.state,
        taxNumber: formData?.tax_number,
        passportIssueDate: formData?.passport_issue_date,
        passportIssuePlace: formData?.passport_issue_place,
        passportNumber: formData?.passport_number
      }
    }
    if(shipInfo.countryId == 198) {
      updateOrderInfo = {
        ...updateOrderInfo,
        taxNumber: formData?.tax_number || ''
      }
    }
  }

  return updateOrderInfo
}

export const updateOrderShipAddress = ({ obj = {} }) => {
  return {
    fname: obj.fname,
    lname: obj.lname,
    fatherName: obj.father_name,
    englishName: obj.english_name,
    middleName: obj.middle_name,
    countryId: obj.country_id,
    country_name: obj.country_name,
    state: obj.state,
    city: obj.city,
    district: obj.district,
    street: obj.street,
    address1: obj.address1,
    address2: obj.address2,
    postcode: obj.postcode,
    taxNumber: obj.tax_number,
    nationalId: obj.nationalId,
    tel: obj.tel,
    standbyTel: obj.standby_tel,
    isDefault: '',
    type: obj.type || 0,
    stateId: obj.stateId,
    cityId: obj.cityId,
    districtId: obj.districtId,
    passportNumber: obj.passport_number,
    passportIssuePlace: obj.passport_issue_place,
    passportIssueDate: obj.passport_issue_date,
    birthday: obj.birthday,
    lat: obj.lat,
    lng: obj.lng,
    calendar_type: obj.calendar_type,
    id_expire_date: obj.id_expire_date
  }
}

export const updateOrderListOrDetailAddress = ({ obj = {} }) => {
  return {
    shipping_firstname: obj.fname,
    shipping_lastname: obj.lname,
    shipping_father_name: obj.father_name || obj.fatherName || '',
    shipping_english_name: obj.english_name || obj.englishName || '',
    shipping_country_id: obj.country_id || obj.countryId,
    shipping_country_name: obj.country_name,
    shipping_province: obj.state,
    shipping_city: obj.city,
    shipping_district: obj.district,
    shipping_street: obj.street,
    shipping_postcode: obj.postcode,
    tax_number: obj.tax_number || obj.taxNumber || '',
    nationalId: obj.nationalId,
    shipping_telephone: obj.tel,
    backupShippingTelephone: obj.standby_tel || obj.standbyTel,
    isDefault: '',
    type: obj.type || 0,
    shipping_state_id: obj.stateId,
    shipping_city_id: obj.cityId,
    shipping_district_id: obj.districtId,
    shipping_address_1: obj.address1,
    address_1: obj.address1,
    shipping_address_2: obj.address2,
    address_2: obj.address2,
    subsidiary: {
      middle_name: obj.middle_name || obj.middleName,
      passport_number: obj.passportNumber,
      issue_place: obj.passport_issue_place || obj.passportIssuePlace,
      issue_date: obj.passport_issue_date || obj.passportIssueDate,
      store_id: obj.storeId || '',
      store_name: obj.storeName || '',
      store_type: obj.storeType || '',
    },
    birthday: obj.birthday,
    shipping_lat: obj.lat,
    shipping_lng: obj.lng,
    calendar_type: obj.calendar_type,
    id_expire_date: obj.id_expire_date
  }
}

// 识别处理电话内容
export function highlightPhoneNumbers(text) {
  // 正则表达式匹配【】中的电话号码
  // 允许的字符包括数字、+、-、()、空格
  const phoneRegex = /【\s*([+\-()\[\]\d\s]+)】/g

  return text.replace(phoneRegex, (match, number) => {
    const cleanNumber = number.replace(/[+\-()\[\]\s]/g, '')
    if (!isNaN(cleanNumber)) {
      return `<span class="track-phone-highlight" onclick="phoneCall('${cleanNumber}')" >${match}</span>`
    }
    return match // 不匹配不替换
  })
}
